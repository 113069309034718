import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import SEO from "../components/SEO"
import NakedList from "../components/NakedList"

const MasonryList = styled(NakedList)`
    padding-bottom: 20vh;
    columns: 2;
    @media (max-width: 768px) {
        columns: 1;
    }
    column-gap: 1rem;
    max-width: calc(1000px + 1rem);
    width: 100%;
    margin: 2vh auto;
    & > *:not(:first-child) {
        margin: 1rem 0;
    }
`

const MediaItem = styled("li")`
    position: relative;
    break-inside: avoid;
`

const Creator = styled("div")`
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 4px;
    padding: 0.2rem 0.5rem 0.1rem;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.88);
`

const MediaPage = ({ data }) => {
    const allImages = data.allImages.edges
        .map(edge => edge.node.data)
        .map(data => ({
            creator: data.Creator,
            file: data.Image.localFiles[0]
        }))
    return (
        <React.Fragment>
            <SEO title="Media" />
            {allImages && (
                <MasonryList>
                    {allImages.map((image, index) => (
                        <MediaItem key={index}>
                            <Image
                                fluid={image.file.childImageSharp.fluid}
                                alt={"Photo by " + image.creator}
                                title={"Photo by " + image.creator}
                            />
                            <Creator>by {image.creator}</Creator>
                        </MediaItem>
                    ))}
                </MasonryList>
            )}
        </React.Fragment>
    )
}

export const query = graphql`
    query GetMedia {
        allImages: allAirtable(filter: { table: { eq: "Images" } }) {
            edges {
                node {
                    data {
                        Creator
                        Image {
                            localFiles {
                                childImageSharp {
                                    fluid(maxWidth: 500, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default MediaPage
